<template>
  <g>
    <svg:style>
      .cls-1, .cls-10, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-1 {
        fill: url(#BG-gradient);
      }

      .cls-2 {
        fill: url(#glass-pattern);
      }

      .cls-3 {
        fill: none;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#inox-gradient-2);
      }

      .cls-6 {
        fill: url(#inox-gradient-3);
      }

      .cls-7 {
        fill: url(#inox-gradient-4);
      }

      .cls-8 {
        fill: url(#inox-gradient-5);
      }

      .cls-9 {
        fill: url(#inox-gradient-6);
      }

      .cls-10 {
        fill: url(#handle-gradient);
      }
      .handle { fill: url(#handle-gradient); }
    </svg:style>
    <linearGradient id="glass-gradient" x1="35.18" y1="279.66" x2="35.18" y2="22.26" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="inox-gradient" x1="89.81" y1="83.2" x2="89.81" y2="81.08" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="inox-gradient-2" data-name="inox" x1="89.81" y1="93.14" x2="89.81" y2="91" xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-3" data-name="inox" x1="89.82" y1="147.06" x2="89.82" y2="144.93" xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-4" data-name="inox" x1="89.81" y1="156.99" x2="89.81" y2="154.86" xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-5" data-name="inox" x1="89.82" y1="210.91" x2="89.82" y2="208.79" xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-6" data-name="inox" x1="89.81" y1="220.85" x2="89.81" y2="218.72" xlink:href="#inox-gradient"/>
    <linearGradient id="handle-gradient" x1="11.34" y1="150.96" x2="17.01" y2="150.96" xlink:href="#inox-gradient"/>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="L20">
      <rect filter="url(#inset-shadow)" id="glass" class="cls-2" :x="`${doorLeftWidth + 22.26}`" :y="`${doorTopHeight + 22.26}`" width="25.83" :height="inoxHeight"/>
      <line class="line"
            v-if="showImpost"
            :x1="`${doorLeftWidth + 59.72}`" :y1="`${inoxHeight + doorTopHeight + inoxOffset}`" :x2="`${doorLeftWidth + 59.72}`" :y2="`${doorTopHeight + 22.26}`"/>
      <rect id="inox"
            v-if="showInox"
            data-name="inox"
            class="cls-4"
            :x="`${doorLeftWidth + inoxL}`"
            :y="`${doorTopHeight + inoxOffset + inox11}`"
            :width="inoxW"
            height="2.12"/>
      <rect id="inox-2"
            v-if="showInox"
            data-name="inox"
            class="cls-5"
            :x="`${doorLeftWidth + inoxL}`"
            :y="`${doorTopHeight + inoxOffset + inox12}`"
            :width="inoxW"
            height="2.13"/>
      <rect id="inox-3"
            v-if="showInox"
            data-name="inox"
            class="cls-6"
            :x="`${doorLeftWidth + inoxL}`"
            :y="`${doorTopHeight + inoxOffset + inox21}`"
            :width="inoxW"
            height="2.12"/>
      <rect id="inox-4"
            v-if="showInox"
            data-name="inox"
            class="cls-7"
            :x="`${doorLeftWidth + inoxL}`"
            :y="`${doorTopHeight + inoxOffset + inox22}`"
            :width="inoxW"
            height="2.13"/>
      <rect id="inox-5"
            v-if="showInox"
            data-name="inox"
            class="cls-8"
            :x="`${doorLeftWidth + inoxL}`"
            :y="`${doorTopHeight + inoxOffset + inox31}`"
            :width="inoxW"
            height="2.12"/>
      <rect id="inox-6"
            v-if="showInox"
            data-name="inox"
            class="cls-9"
            :x="`${doorLeftWidth + inoxL}`"
            :y="`${doorTopHeight + inoxOffset + inox32}`"
            :width="inoxW"
            height="2.13"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inoxOffset: 22.26,
      leaf2Left: 7,
    }
  },
  computed: {
    inoxL() {
      return this.showBg ?
        59.58 : 48.4
    },
    showImpost() {
      return this.showBg;
    },
    inoxW() {
      return this.showBg ?
        this.doorWidth - this.inoxOffset - 59.58 :
        this.doorWidth - this.leaf2Left - 48.2
    },
    inox11() {
      return this.inoxHeight * .25 - 3
    },
    inox12() {
      return this.inoxHeight * .25 + 3
    },
    inox21() {
      return this.inoxHeight * .5 - 3
    },
    inox22() {
      return this.inoxHeight * .5 + 3
    },
    inox31() {
      return this.inoxHeight * .75 - 3
    },
    inox32() {
      return this.inoxHeight * .75 + 3
    },
    inoxHeight() {
      return this.doorHeight - 2 * this.inoxOffset
    },
  }
}
</script>
